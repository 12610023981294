// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

import { withCSS } from "framer"
import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import { useEffect } from "react"

const css = `
    .supporta-campaigns-count {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: #2d2d2d;
    }
    @media only screen and (max-width: 1149px) {
        .supporta-campaigns-count {
            font-size: 20px;
            line-height: 30px;
            height: 30px;
        }
    }
    @media only screen and (min-width: 1150px) {
        .supporta-campaigns-count {
            font-size: 40px;
            line-height: 45px;
            height: 45px;
        }
    }
`

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Aantal_deelnemers(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    useEffect(() => {
        const script = document.createElement("script")

        script.src = "https://cdn.supporta.cc/embed.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <span
                class="supporta-campaigns-count"
                data-charity="218s"
                data-projects="613k3o,035zq7zgd6"
                data-tickets="LB59y8hDY8EX,LB59uFa2339X,LB59D536NPDG,LB59MHLGAtRu,LB59j16nODqp,LB59waKkpuus,LB59nNC90QFe,LB59l2zb7pLH"
                data-include-teams="false"
            ></span>

            <style>{css}</style>
        </div>
    )
}
